/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pumpjack-image {
  max-width: 100%;
  height: auto;
  margin: 2em 0;
}
/* Header */
.header {
  background-color: #1a1a1a;
  padding: 1em 0;
  color: #fff;
  text-align: center;
}

.header h1 {
  margin: 0;
}

/* Main Content */
.main-content {
  flex: 1;
  text-align: center;
  padding: 2em 1em;
}

.main-content p {
  font-size: 1.5em;
  color: #555;
}

/* Informational Squares */
.info-squares {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;
}

.square {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  width: 250px;
  margin: 1em;
  padding: 1.5em;
  text-align: center;
  border-radius: 5px;
}

.square h2 {
  margin-top: 0;
  font-size: 1.25em;
}

.square p {
  color: #666;
}

/* Contact Button */
.contact-button {
  display: inline-block;
  margin-top: 2em;
  padding: 0.75em 1.5em;
  background-color: #e85d04;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.contact-button:hover {
  background-color: #d35400; /* A darker shade */
}

/* Footer */
.footer {
  background-color: #1a1a1a;
  padding: 1em 0;
  color: #fff;
  text-align: center;
}

.footer p {
  margin: 0;
  font-size: 0.9em;
}